<template>
    <div class="chart-container">
		<v-overlay
			:opacity="0.15"
			:value="loader"
			absolute
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					/>
				</v-col>
			</v-row>
		</v-overlay>
		<v-card elevation="6" class="mx-auto">
			<v-card-text>
				<div class="black--text darken-1 label">{{ label1 }}</div>
				<div class="black--text darken-1">{{ label2 }}</div>
				<div class="error-container" v-if="loadingError">
					<v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
				</div>
				<ECharts
					:options="getChartOptions()"
					:style="cssVars"
					autoresize
					@click="params => openBarDetails(params)"
					@rendered="rendered()"
				/>
			</v-card-text>
		</v-card>
    </div>
</template>

<script>
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/axisPointer";
import "echarts/lib/component/graphic";

import ECharts from "vue-echarts";
import {api} from "Api";
import {log} from "Helpers/helpers";
export default {
	name: "GrossMarginForecastChart",
    props: ['conditions'],
    components: {ECharts},
    data(){
		return {
			areaAboveTarget: [],
            areaBelowMinimum: [],
            areaBelowTarget: [],
			chartHeight: '420px',
			commissionIntervalValue: 200,
			cumulativeSales: [],
			currentCommissionGapValue: 0,
			currentCommissionValue: 0,
			currentCommissionDataArray: [],
			gmDailyIntake: [],
			errorMessage: null,
			label1: '',
			label2: '',
			loader: true,
			loadingError: false,
			maxCommissionValue: 0,
            minimumMonthlyMargin: 0,
			projectedSales: [],
			projectedSalesDataArray: [],
			targetCommissionValue: 0,
			targetCommissionDataArray: [],
			yAxisLabel1: '',
			yAxisMax3: 0,
			yAxisInterval3: 0,
			xAxisData1: [],
			xAxisData2: []
        }
    },
	computed: {
		chartLabel() {
			if (this.label != null) return this.label
			return this.$t('message.chartLabel')
		},
		cssVars() {
			let width = '100%'
			let height = this.chartHeight
			return {
				'--chart-width': width,
				'--chart-height': height,
			}
		},
	},
    methods: {
		getChartOptions () {
			let series = [
				{
					type: 'line',
					data: this.cumulativeSales,
					lineStyle: {
						color: 'green',
					},
					markLine: {
						label: {
							position: 'insideEndTop',
							distance: [10,5],
							formatter: 'Quarterly Excellence',
							fontFamily: 'Roboto, sans-serif',
							fontSize: 16,
							color: 'blue',

						},
						lineStyle: {
							color: 'blue'
						},
						symbol: ['circle','circle'],
						data: [
							{
								yAxis: 100
							}
						]
					},
				},
                {
                    type: 'line',
                    markLine: {
                        label: {
                            position: 'insideEndBottom',
                            distance: [10,5],
                            formatter: 'Quarterly Min. Margin',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 16,
                            color: 'red',

                        },
                        lineStyle: {
                            color: 'red'
                        },
                        symbol: ['circle','circle'],
                        data: [
                            {
                                yAxis: this.minimumMonthlyMargin
                            }
                        ]
                    },
                },
				{
					type: 'line',
					name: 'Projected GM Intake 1',
					data: this.projectedSalesDataArray,
					connectNulls: true,
					lineStyle: {
						color: 'red',
						type: 'dashed'
					}
				},
				// {
				// 	type: 'bar',
				// 	legendHoverLink: false,
				// 	barWidth: '100%',
				// 	stack: 'area',
				// 	data: this.areaBelowTarget,
				// 	itemStyle: {
				// 		color: '#82A05A',
				// 	},
				// 	showBackground: true,
				// 	backgroundStyle: {
				// 		borderWidth: 0,
				// 		color: '#3C6932',
				// 		opacity: 1
				// 	},
				// 	silent: true,
				// },
				{
					type: 'line',
					clip: false,
					symbol: 'none',
					label: {
						show: false
					},
					legendHoverLink: false,
					data: this.areaBelowTarget,
					areaStyle: {
						color: '#82A05A',
						opacity: 1
					},
					silent: true,
				},
                {
                  type: "line",
                    clip: false,
                    symbol: 'none',
                    label: {
                        show: false
                    },
                    legendHoverLink: false,
                    data: this.areaBelowMinimum,
                    areaStyle: {
                        color: '#FFE082',
                        opacity: 1
                    },
                    silent: true,
                }
			]
			return {
				tooltip: {
					show: true,
					trigger: 'item',
					type: 'cross',
					formatter: '{b0}: {c0}%'
				},
                xAxis: [
					{
						data: this.xAxisData1,
						type: 'category',
						axisLine: {
							lineStyle: {
								color: 'black'
							}
						},
						axisLabel: {
							fontFamily: 'Roboto, sans-serif',
							fontSize: 14,
							color: 'black'
						},
						show: false,
						axisPointer: {
							show: true,
							snap: true
						},
					},
					{
						axisPointer: {
							show: false
						},
						name: 'Current Q Weeks',
						nameTextStyle: {
							padding: [20, 5],
							fontFamily: 'Roboto, sans-serif',
							fontSize: 16,
							color: 'black'
						},
						nameLocation: 'center',
						position: 'bottom',
						type: 'category',
						data: this.xAxisData2,
						xAxisIndex: 2,
						interval: 1,
						axisLabel: {
							fontFamily: 'Roboto, sans-serif',
							fontSize: 14,
							color: 'black'
						}
					}
				],
				yAxis: [
					{
						name: null,
						nameTextStyle: {
							fontFamily: 'Roboto, sans-serif',
							fontSize: 16,
							color: 'black',
							align: 'left'
						},
						type: 'value',
						splitLine: {
							show: false
						},
						min: 0,
						interval: 20,
						max: 160,
						axisLabel: {
							formatter: '{value}%',
							fontFamily: 'Roboto, sans-serif',
							fontSize: 14,
							color: 'black'
						},
						axisPointer: {
							show: true,
							snap: true
						},
						show: false
					},
					{
						position: 'left',
						name: null,
						type: 'value',
						splitLine: {
							show: false
						},
						axisLabel: {
							formatter: (value)=>{
								return '$' + Math.round(value).toString() + 'K'
							},
							fontFamily: 'Roboto, sans-serif',
							fontSize: 14,
							color: 'black'
						},
						axisPointer: {
							show: false
						},
						min: 0,
						interval: this.yAxisInterval3,
						max: this.yAxisMax3,
					},
					{
						name: null,
						type: 'value',
						min: 0,
						interval: 20,
						max: 160,
						splitLine: {
							show: false
						},
						axisLabel: {
							formatter: '{value}%',
							fontFamily: 'Roboto, sans-serif',
							fontSize: 14,
							color: 'black'
						},
						axisPointer: {
							show: false
						},
						show: true
					},
					// {
					// 	name: null,
					// 	type: 'value',
					// 	splitLine: {
					// 		show: false
					// 	},
					// 	axisLabel: {
					// 		formatter: '${value}K',
					// 		fontFamily: 'Roboto',
					// 		fontSize: 14,
					// 		color: 'black'
					// 	},
					// 	axisPointer: {
					// 		show: false
					// 	},
					// 	min: 0,
					// 	interval: this.yAxisInterval3,
					// 	max: this.yAxisMax3,
					// }
				],
				series: series,
				grid: {
					backgroundColor: '#11B1CC',
					show: true,
					left: 55,
					right: 55,
					top: 15
				},
				graphic: {
					elements: [
						{
							type: 'text',
							z: 10,
							style: {
								text: '4%',
								y: 160,
								x: 75,
								font: 'bolder 14px'
							}
						},
						{
							type: 'text',
							z: 10,
							style: {
								text: '5%',
								y: 50,
								x: 75,
								font: 'bolder 14px'
							}
						}
					]
				}
            }
        },
		getValues() {
			return new Promise((resolve, reject) => {
				const url = '/sparkline/chart/gm-current-daily-intake'
				if(url) {
					api
						.get(url, {
							params: {
								conditions: this.conditions ? this.conditions : []
							}
						})
						.then((response) => {
							const data = response.data.data
							resolve(data)
						})
						.catch((error) => {
							reject(error)
						})
				} else {
					reject('error')
				}
			})
		},
		handleResize() {
			// if(window.innerHeight - (375) > 400) this.chartHeight = window.innerHeight - (375) + 'px';
		},
		openBarDetails (params) {
		},
		rendered (){
			// this.loader = false;
		},
    },
	created() {
		window.addEventListener('resize', this.handleResize)
	},
    mounted() {
		this.getValues()
			.then((data) => {
				this.label1 = data.label1
				this.label2 = data.label2
				this.cumulativeSales = data.cumulativeSales
				this.cumulativeSales.forEach((s) => {
					this.projectedSalesDataArray.push(null)
					// this.areaBelowTarget.push(100)
					// this.areaAboveTarget.push(60)
				})
				this.currentCommissionGapValue = data.currentCommissionGapValue
				this.commissionIntervalValue = data.commissionIntervalValue
				this.currentCommissionValue = data.currentCommissionValue
                this.minimumMonthlyMargin = data.minimumMonthlyMargin
				this.maxCommissionValue = data.maxCommissionValue
				this.projectedSales = data.projectedSales
				this.projectedSalesDataArray = [...this.projectedSalesDataArray, ...this.projectedSales]
				this.targetCommissionValue = data.targetCommissionValue
				this.gmDailyIntake = data.gmDailyIntake
				this.yAxisLabel1 = data.yAxisLabel1
				this.xAxisData1 = data.xAxisData1
				this.xAxisData2 = data.xAxisData2

				this.yAxisMax3 = data.yAxisMax3
				this.yAxisInterval3 = data.yAxisInterval3

				this.targetCommissionDataArray = []
				this.currentCommissionDataArray = []
				this.xAxisData1.forEach((d) => {
					this.targetCommissionDataArray.push(null);
					this.currentCommissionDataArray.push(null);
					this.areaBelowTarget.push(100);
                    this.areaBelowMinimum.push(this.minimumMonthlyMargin);
				})
				this.targetCommissionDataArray.pop()
				this.currentCommissionDataArray.pop()
				this.targetCommissionDataArray.push(this.currentCommissionGapValue)
				this.currentCommissionDataArray.push(this.currentCommissionValue)
				this.maxCommissionValue = data.maxCommissionValue
				this.loader = false
				this.handleResize()
			})
			.catch((error) => {
				this.loader = false
				this.loadingError = true
				this.errorMessage = error
			})
    },
	beforeUpdate() {
		this.handleResize()
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize)
	}
}
</script>

<style>
.chart-container {
    position: relative;
}
.echarts {
	width: var(--chart-width);
	height: var(--chart-height);
}
.label {
	font-weight: bold;
	font-size: 1.25em !important;
}
</style>